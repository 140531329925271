import React, { useState, useEffect, useContext } from 'react';
import axios from '../Services/axiosInterceptor'; // Importando o axios configurado com interceptor
import { SlugContext } from '../context/SlugContext';
import { AuthContext } from '../context/AuthContext';
import { BandaContext } from '../context/BandaContext';
import { RegisterContext } from '../context/RegisterContext';
import MusicContext from '../context/MusicContext';
import BotoesSociais from '../Components/BotoesSociais'; 
import Album from '../Components/Album'; 
import MusicasBanda from '../Components/MusicasBanda'; 
import Eventos from '../Components/Eventos'; 
import SocialMedia from '../Components/SocialMedia'; 
import ModalMenuOpcoes from '../Components/ModalMenuOpcoes';
import DescriBanda from '../Components/DescriBanda';
import TabBar from '../Components/TabBar'; 
import MiniPlayer from '../Components/MiniPlayer';
import AlbunsFotos from '../Components/AlbunsFotos';
import BandaVideos from '../Components/BandaVideos';
import ProfileAlert from '../utils/ProfileAlert';

interface Band {
  generos: string[];
  url_capa_banda?: string;
  url_icone_banda?: string;
  nome_banda?: string;
  descricao?: string;
  id_banda?: number;
  slug?: string;
}

const TelaBandaPerfil: React.FC = () => {
  const [band, setBand] = useState<Band>({generos: []});
  const { state: slugState } = useContext(SlugContext);
  const { dispatch } = useContext(BandaContext);
  const { dispatch: registerDispatch } = useContext(RegisterContext);
  const { state: authState } = useContext(AuthContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const musicContext = useContext(MusicContext);
  const currentTrack = musicContext?.state.track;
  const [missingInfo, setMissingInfo] = useState<string[]>([]);
  const [showAlert, setShowAlert] = useState(false);
  const [idConversa, setIdConversa] = useState<number | null>(null);
  const [isOwner, setIsOwner] = useState(false); 
  const [coverBackgroundColor, setCoverBackgroundColor] = useState("#2a2e39");

  useEffect(() => {
    // Atualiza o valor de isOwner sempre que authState ou band mudarem
    setIsOwner(authState.id_banda === band.id_banda);
  }, [authState.id_banda, band.id_banda]);

  useEffect(() => {
    const fetchData = async () => {
      let id_banda = authState.id_banda;
      console.log('log dentro do perfil da banda', slugState.slugPerfil, authState.id_banda, authState.slug, authState.token);

      if (slugState.slugPerfil) {
        try {
          const response = await axios.get(`https://tribhus.shop:5000/bands/get-band-id/${slugState.slugPerfil}`);
          id_banda = response.data.id_banda;
        } catch (error) {
          console.error('Erro ao buscar ID da banda:', error);
          return;
        }
      }

      if (!id_banda) {
        console.error("id_banda é inválido:", id_banda);
        return;
      }
      
     
      try {
        const bandDetails = await axios.get(`https://tribhus.shop:5000/bands/banda/${id_banda}`);
        setBand(bandDetails.data);
        dispatch({ type: 'SET_BANDA', banda: bandDetails.data });
        registerDispatch({ type: 'SET_BAND_ID', id: id_banda });
        
        // Check for missing information
        const missing: string[] = [];
        if (!bandDetails.data.url_icone_banda) missing.push('photo');
        if (!bandDetails.data.descricao) missing.push('description');
        if (!bandDetails.data.generos || bandDetails.data.generos.length === 0) missing.push('genres');
        
        setMissingInfo(missing);
        setShowAlert(missing.length > 0);
      } catch (error) {
        console.error("Erro ao buscar detalhes da banda:", error);
      }
    };

    fetchData();
  }, [authState.id_banda, slugState.slugPerfil, authState.slug, authState.token]);
    
   // const handleEditProfile = () => {
    //  console.log("Editar perfil");
    //  setIsMenuOpen(false);
   // };
   // const handleLogout = () => {
    //  console.log("Logout");
    //  setIsMenuOpen(false);
   // };

   const fetchIdConversa = async () => {
    const idParticipante1 = authState.id_usuario || authState.id_banda;
    const idParticipante2 = band.id_banda;
  
    if (idParticipante1 === idParticipante2 || !idParticipante1 || !idParticipante2) {
      return;
    }
  
    try {
      const response = await axios.get(`https://tribhus.shop:5000/mensagens/find-conversa/${idParticipante1}/${idParticipante2}`, {
        headers: {
          Authorization: `Bearer ${authState.token}`
        }
      });
      setIdConversa(response.data.idConversa);
    } catch (error) {
      console.error("Erro ao buscar idConversa:", error);
    }
  };

  useEffect(() => {
    fetchIdConversa();
  }, [authState.id_usuario, authState.id_banda, band.id_banda, authState.token]);
  
// Função para extrair a cor dominante do ícone da banda
const getImagePrimaryColor = (imageUrl: string): Promise<string> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.crossOrigin = "Anonymous"; // Permite leitura de imagens externas
    img.src = imageUrl;

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      if (!ctx) {
        resolve("#2a2e39"); // Retorna uma cor padrão caso o contexto seja nulo
        return;
      }

      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0, img.width, img.height);

      // Pegando a média de cor do canto superior esquerdo
      const pixelData = ctx.getImageData(0, 0, 1, 1).data;
      const color = `rgb(${pixelData[0]}, ${pixelData[1]}, ${pixelData[2]})`;

      resolve(color);
    };

    img.onerror = () => resolve("#2a2e39"); // Cor fallback caso a imagem falhe
  });
};
  
    // Quando a página carregar, obtém a cor do ícone
    useEffect(() => {
      if (!band.url_capa_banda && band.url_icone_banda) {
        getImagePrimaryColor(band.url_icone_banda).then(setCoverBackgroundColor);
      }
    }, [band]);

   return (
    <div style={styles.wrapper}>
    <div style={styles.container}>
      {showAlert && isOwner && (
        <ProfileAlert 
          missingInfo={missingInfo} 
          onClose={() => setShowAlert(false)} 
        />
      )}
        {/* Cabeçalho com imagem de capa e ícone de perfil */}
        <div style={styles.headerContainer}>
                   {/* Imagem de capa com cor de fundo dinâmica */}
                   <div
            style={{
              ...styles.coverImage,
              backgroundImage: band.url_capa_banda ? `url(${band.url_capa_banda})` : "none",
              backgroundColor: band.url_capa_banda ? "transparent" : coverBackgroundColor,
              border: "2px solid rgba(255,255,255,0.2)", // Borda sutil
            }}
          />
            
          {/* Ícone de perfil */}
          <div style={{
            ...styles.profileIcon,
            backgroundImage: `url(${band.url_icone_banda})`,
          }} />
     
        {/* Botões Sociais */}
        {band.id_banda && (
          <BotoesSociais
            idBandaRecebe={band.id_banda}
            isOwnProfile={authState.id_banda === band.id_banda}
            idConversa={idConversa}
          />
        )}

          {/* Botão de menu */}
          <button
            style={styles.menuButton}
            onClick={() => setIsMenuOpen(true)}
          >
            ⋮
          </button>
        </div>

      {/* Modal Menu Opcoes */}
      <ModalMenuOpcoes
  isOpen={isMenuOpen}
  onClose={() => setIsMenuOpen(false)}
  isOwner={isOwner}
  itemId={band.id_banda ?? ''}
  tipoItem="banda"
/>
          {/* Conteúdo principal com TabBar e Feed */}
        <div style={styles.mainContent}>
          {/* Coluna do TabBar */}
          <div style={styles.tabBarColumn}>
            <TabBar currentScreen="Home" currentSlug={band.slug}/>
            {currentTrack && (
            <div style={styles.miniPlayerContainer}>
              <MiniPlayer />
            </div>
          )}
       </div>
  
        {/* Coluna do Feed */}
      <div style={styles.feedColumn}>
        <h1>{band.nome_banda}</h1>

        <div className="generos" style={styles.generosContainer}>
          {band.generos.map((genero, index) => (
            <span key={index} style={styles.genreTag}>
              {genero}
            </span>
          ))}
        </div>

        <DescriBanda descricao={band.descricao || ''} />

        {/* Verificar se id_banda e token estão disponíveis antes de renderizar os componentes */}
        {authState.token && band.id_banda ? (
          <>
            <Album id_banda={band.id_banda} token={authState.token} />

            <MusicasBanda idBanda={band.id_banda} />

            <Eventos id_banda={band.id_banda} token={authState.token} isOwner={isOwner} />

            <AlbunsFotos id_banda={band.id_banda} token={authState.token} />

            <BandaVideos id_banda={band.id_banda} token={authState.token} />

            <SocialMedia id_banda={band.id_banda} token={authState.token} />
          </>
        ) : (
          <div>Carregando dados da banda...</div>
        )}
      </div>
    </div>
  </div>
  </div>
);
};
  
  const styles: { [key: string]: React.CSSProperties } = {
    wrapper: {
      backgroundColor: '#151922',
      minHeight: '100vh',
      width: '100%',
    },
    container: {
      backgroundColor: '#151922',
      minHeight: '100vh',
      color: 'white',
      padding: '20px',
      maxWidth: '1200px',  // ou outro valor que preferir
      margin: '0 auto',    // para centralizar
  },
    headerContainer: {
      position: 'relative',
      width: '100%',
      height: '200px',
      marginBottom: '120px',
    },
    coverImage: {
      width: "100%",
      height: "120%",
      backgroundSize: "cover",
      backgroundPosition: "center",
      borderRadius: "8px",
    },
    profileIcon: {
      position: 'absolute',
      bottom: '-80px',
      left: '980px',
      width: '180px',
      height: '180px',
      borderRadius: '50%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      border: '4px solid #151922',
      boxShadow: '0 2px 4px rgba(255,255,255,0.2)',
      marginBottom: '-30px',
    },
    menuButton: {
      position: 'absolute',
      bottom: '-85px',
      left: '1180px',
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      backgroundColor: '#151922',
      color: 'white',
      fontSize: '24px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: 'none',
      cursor: 'pointer',
      boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
    },
    mainContent: {
      display: 'flex',
      padding: '0 20px',
    },
    tabBarColumn: {
      width: '20%',
      paddingRight: '20px',
    },
    feedColumn: {
      flex: 1,
      overflowY: 'auto',
    },
    genreTag: {
      display: 'inline-block',
      backgroundColor: '#2a2e39',
      color: 'white',
      padding: '5px 10px',
      borderRadius: '15px',
      margin: '5px',
      fontSize: '14px',
     },
    generosContainer: {
      marginBottom: '30px', // Adicione a margem inferior aqui
    },
  };
  
  export default TelaBandaPerfil;