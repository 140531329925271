import React, { useState, useEffect, useRef } from 'react';
import axios from '../Services/axiosInterceptor'; 

interface Foto {
  id_foto: number;
  url_foto: string;
  descricao: string | null;
  titulo: string | null;
  caminho_arquivo: string;
  data_upload: string;
  id_album_foto: number;
}

interface AlbumFotos {
  id_album_foto: number;
  nome: string;
  descricao: string;
  id_proprietario: number;
  tipo_proprietario: string;
  capa?: string;
  data_criacao?: string;
}

interface ModalFotosProps {
  isOpen: boolean;
  album: AlbumFotos;
  onClose: () => void;
  token: string;
}

const ModalFotos: React.FC<ModalFotosProps> = ({ isOpen, album, onClose, token }) => {
  const [fotos, setFotos] = useState<Foto[]>([]);
  const [selectedFoto, setSelectedFoto] = useState<Foto | null>(null);
  const carouselRef = useRef<HTMLDivElement>(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);

  useEffect(() => {
    const buscarFotosDoAlbum = async (id_album_foto: number) => {
      if (!id_album_foto) {
        console.error('ID do álbum de fotos é inválido:', id_album_foto);
        return;
      }

      try {
        const response = await axios.get(`https://tribhus.shop:5000/fotos/fotos/${id_album_foto}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setFotos(response.data);
        console.log('URLs das fotos:', response.data.map((foto: Foto) => foto.url_foto));
      } catch (error) {
        console.error('Erro ao buscar fotos do álbum:', error);
      }
    };

    if (album && token && album.id_album_foto) {
      buscarFotosDoAlbum(album.id_album_foto);
    }
  }, [album, token]);

  const handleFotoClick = (foto: Foto) => {
    setSelectedFoto(foto);
  };

  const handleCloseFullscreen = () => {
    setSelectedFoto(null);
  };

  const handleScroll = (direction: 'left' | 'right') => {
    if (carouselRef.current) {
      const scrollAmount = direction === 'left' ? -200 : 200;
      carouselRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const checkArrowsVisibility = () => {
    if (carouselRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
    }
  };

  useEffect(() => {
    const carouselElement = carouselRef.current;
    if (carouselElement) {
      carouselElement.addEventListener('scroll', checkArrowsVisibility);
      checkArrowsVisibility();
    }
    return () => {
      if (carouselElement) {
        carouselElement.removeEventListener('scroll', checkArrowsVisibility);
      }
    };
  }, [fotos]);

  if (!isOpen || !album) {
    return null;
  }

  return (
    <div style={styles.overlay}>
      <div style={styles.modal}>
        <button style={styles.closeButton} onClick={onClose}>Fechar</button>
        <div style={styles.content}>
          <div style={styles.albumInfo}>
            <span style={styles.albumName}>{album.nome}</span>
            <span style={styles.albumDate}>
              {album.data_criacao
                ? new Date(album.data_criacao).toLocaleDateString()
                : 'Data não disponível'}
            </span>
          </div>
        </div>
        <div style={styles.carouselContainer}>
          {showLeftArrow && (
            <button style={{...styles.arrowButton, left: 0}} onClick={() => handleScroll('left')}>&#9664;</button>
          )}
          <div style={styles.carousel} ref={carouselRef}>
            {fotos.map(foto => (
              <img 
                key={foto.id_foto} 
                src={foto.url_foto} 
                alt={foto.titulo || foto.descricao || 'Sem descrição'}
                style={styles.fotoThumbnail}
                onClick={() => handleFotoClick(foto)}
              />
            ))}
          </div>
          {showRightArrow && (
            <button style={{...styles.arrowButton, right: 0}} onClick={() => handleScroll('right')}>&#9654;</button>
          )}
        </div>
      </div>
      {selectedFoto && (
        <div style={styles.fullscreenOverlay} onClick={handleCloseFullscreen}>
          <img 
            src={selectedFoto.url_foto} 
            alt={selectedFoto.titulo || selectedFoto.descricao || 'Sem descrição'}
            style={styles.fullscreenImage}
          />
      </div>
      )}
    </div>
  );
}

const styles = {
  overlay: {
    position: 'fixed' as const,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modal: {
    backgroundColor: '#202020',
    borderRadius: '8px',
    padding: '20px',
    maxWidth: '600px',
    width: '100%',
    maxHeight: '80vh',
    overflowY: 'auto' as const,
    position: 'relative' as const,
  },
  albumNameContainer: {
    position: 'absolute' as 'absolute', // Tipagem explícita
    top: '10px', // Valor válido para 'top'
    left: '20px', // Valor válido para 'left'
  },
  albumName: {
    color: '#914100', // Cor do texto
    fontSize: '2rem', // Reduzido para tamanho discreto
    fontWeight: 'normal', // Remove negrito
    margin: 0, // Remove margens padrão
  },
  closeButton: {
    position: 'absolute' as const,
    top: '10px',
    right: '10px',
    background: 'none',
    border: 'none',
    color: 'white',
    fontSize: '18px',
    cursor: 'pointer',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
  },
  albumCover: {
    width: '100px',
    height: '100px',
    objectFit: 'cover' as const,
    marginRight: '20px',
    borderRadius: '4px',
  },
  albumInfo: {
    display: 'flex',
    flexDirection: 'column' as React.CSSProperties['flexDirection'], // Organiza em coluna
    alignItems: 'flex-start' as React.CSSProperties['alignItems'], // Alinha à esquerda
    gap: '4px', // Espaçamento entre o nome e a data
  },ap: '4px', // Funciona sem ajuste adicional
  albumTitle: {
    color: 'white',
    margin: '0 0 10px 0',
  },
  albumDate: {
    color: '#999',
    fontSize: '1rem',
  },
  carouselContainer: {
    position: 'relative' as const,
    width: '100%',
    height: '200px',
  },
  carousel: {
    display: 'flex',
    overflowX: 'auto' as const,
    scrollSnapType: 'x mandatory' as const,
    scrollbarWidth: 'none' as const,
    msOverflowStyle: 'none' as const,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  fotoThumbnail: {
    width: '180px',
    height: '180px',
    objectFit: 'cover' as const,
    margin: '0 10px',
    borderRadius: '4px',
    cursor: 'pointer',
    flexShrink: 0,
    scrollSnapAlign: 'center' as const,
  },
  arrowButton: {
    position: 'absolute' as const,
    top: '50%',
    transform: 'translateY(-50%)',
    background: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    border: 'none',
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    zIndex: 1,
  },
  fullscreenOverlay: {
    position: 'fixed' as const,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1100,
  },
  fullscreenImage: {
    maxWidth: '90%',
    maxHeight: '80%',
    objectFit: 'contain' as const,
  },
  fotoDescricao: {
    color: 'white',
    marginTop: '10px',
    textAlign: 'center' as const,
  },
};

export default ModalFotos;