import React from 'react';
import { useNavigate } from 'react-router-dom';
import BotaoAjuda from '../Components/BotaoAjuda'; 

const TerceiraTela = () => {
  const navigate = useNavigate();

  const handleUserButton = () => {
    navigate('/QuartaTela');
  };

  const handleBandButton = () => {
    navigate('/TelaBanda1');
  };

  return (
    <div style={styles.container}>
      <div style={styles.buttonContainer}>
        <img src={require('../assets/logotipo.png')} alt="Logotipo" style={styles.logo} />
        
        <button style={styles.button} onClick={handleUserButton}>
          <img src={require('../assets/usuario.png')} alt="Usuário" style={styles.image} />
          <div style={styles.textContainer}>
            <span style={styles.text}>Sou Fã</span>
          </div>
        </button>

        <button style={styles.button} onClick={handleBandButton}>
          <img src={require('../assets/cadbanda.png')} alt="Banda" style={styles.image} />
          <div style={styles.textContainer}>
            <span style={styles.text}>Sou Banda</span>
          </div>
        </button>
      </div>

      {/* Botão de Ajuda com a tela atual */}
      <BotaoAjuda telaAtual="TerceiraTela" />
    </div>
  );
};

const styles: {
  container: React.CSSProperties,
  buttonContainer: React.CSSProperties,
  button: React.CSSProperties,
  image: React.CSSProperties,
  textContainer: React.CSSProperties,
  text: React.CSSProperties,
  logo: React.CSSProperties,
} = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#151922'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row', // Alinha os botões na horizontal
    justifyContent: 'center',
    alignItems: 'center',
    gap: '100px', // Espaçamento entre os botões
    marginTop: '10px',
  },
  button: {
    backgroundColor: '#151922',
    borderColor: '#914100',
    borderWidth: 3,
    borderRadius: 10,
    margin: 20,
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    borderStyle: 'solid'
  },
  image: {
    width: 150,
    height: 150
  },
  textContainer: {
    backgroundColor: '#914100',
    padding: 6,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10
  },
  text: {
    color: '#fff',
    textAlign: 'center' 
  },
  logo: {
    width: '30%',
    height: 'auto',
    marginBottom: '10px'
  }
};

export default TerceiraTela;
