import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import axios from '../Services/axiosInterceptor'; 
import { AuthContext } from '../context/AuthContext';
import { SlugContext } from '../context/SlugContext';
import MusicContext from '../context/MusicContext';
import { useNavigate } from 'react-router-dom';
import LikeButton from '../Components/LikeButton';
import DeletePostComment from '../Components/DeletePostComment';
import MenuButton from '../Services/MenuButton'; 
import YouTube from 'react-youtube';
import TabBar from '../Components/TabBar'; 
import Postagem from './Postagem';
import CreatePost from './CreatePost';
import AddPost from '../assets/add_circle_active.png';
import MiniPlayer from '../Components/MiniPlayer';
import BotaoDenuncias from '../Components/BotaoDenuncias';
import commentIcon from '../assets/com.png'; 
import noImage from '../assets/no_image.png';

interface Postagem {
  id_postagem: number;
  nome: string;
  sobrenome?: string;
  url_capa?: string;
  image_url?: string;
  video_url?: string;
  conteudo: string;
  data_hora: string;
  likes_count?: number;
  user_or_banda_liked_id?: number;
  comentarios_ativados?: boolean;
  id_usuario?: number;
  id_banda?: number;
  slug: string;
}

interface Band {
  generos: string[];
  url_capa_banda?: string;
  cidade?: string;
  estado?: string;
  url_icone_banda?: string;
  nome_banda?: string;
  descricao?: string;
  id_banda?: number;
  slug?: string;
}

interface User {
  generos: string[];
  url_capa_usuario?: string;
  url_icone_usuario?: string;
  nome?: string;
  sobrenome?: string;
  descricao?: string;
  id_usuario?: number;
  cidade?: string;
  estado?: string;
  slug?: string;
}

interface Comment {
  id_comentario: number;
  texto: string;
  data_hora: string;
  nome: string;
  sobrenome?: string; 
  url_perfil?: string;
  id_usuario?: number; 
  id_banda?: number;
  slug: string; 
}

type ProfileData = Band | User;


// Função para extrair o ID do vídeo do URL do YouTube
const extractYouTubeVideoId = (url: string): string | null => {
  const regExp = /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/|shorts\/)([^"&?/ ]{11})/;
  const match = url.match(regExp);
  return match ? match[1] : null;
};

const YouTubeVideo = React.forwardRef<{ pause: () => void; play: () => void }, { videoId: string }>(({ videoId }, ref) => {
  const playerRef = useRef<any>(null);

  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      autoplay: 0,
    },
  };

  const onReady = (event: any) => {
    playerRef.current = event.target;
  };

  React.useImperativeHandle(ref, () => ({
    pause: () => {
      if (playerRef.current) {
        playerRef.current.pauseVideo();
      }
    },
    play: () => {
      if (playerRef.current) {
        playerRef.current.playVideo();
      }
    }
  }));

  return <YouTube videoId={videoId} opts={opts} onReady={onReady} />;
});

// Definindo o estilo responsivo
const responsiveStyles = `
  @media (min-width: 1920px) {
    .mainContent {
      gap: 40px; /* Aumenta o espaçamento entre as colunas em telas maiores */
    }
    .feedColumn {
      max-width: 800px; /* Expande um pouco a largura da coluna do feed */
    }
  }

  @media (max-width: 1200px) {
    .mainContent {
      gap: 10px; /* Reduz o espaçamento entre as colunas em telas menores */
    }
    .feedColumn {
      max-width: 100%; /* Usa toda a largura disponível em telas menores */
    }
    .rightColumn, .leftColumn {
      display: none; /* Oculta as colunas laterais em telas muito pequenas */
    }
  }
`;

const StyleSheet = () => (
  <style>
    {responsiveStyles}
  </style>
);

const Palco: React.FC = () => {
  const [posts, setPosts] = useState<Postagem[]>([]);
  const [profileData, setProfileData] = useState<ProfileData | null>(null);
  const [postComments, setPostComments] = useState<{ [key: number]: Comment[] }>({});
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedPostId, setSelectedPostId] = useState<number | null>(null);
  const [selectedCommentId, setSelectedCommentId] = useState<number | null>(null);
  const [bandasEmDestaque, setBandasEmDestaque] = useState<Band[]>([]);
  const [loading, setLoading] = useState(false);
  const { state: authState } = useContext(AuthContext);
  const { id_usuario, id_banda, token } = authState;
  const { dispatch: slugDispatch } = useContext(SlugContext);
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const playerRefs = useRef<{ [key: number]: { pause: () => void } }>({});
  const [isCreatePostOpen, setIsCreatePostOpen] = useState(false); 
  const musicContext = useContext(MusicContext);
  const currentTrack = musicContext?.state.track;

  useEffect(() => {
    console.log("AuthState:", authState);
    if (token) {
      fetchProfileData();
      fetchPosts();
    } else {
      console.log("Token não disponível no AuthContext");
    }
  }, [token, id_usuario, id_banda]);

  const fetchProfileData = async () => {
    if (!token) {
      console.log("Token não disponível para a requisição");
      return;
    }

    try {
      let response;
      if (id_usuario) {
        console.log(`Buscando dados do usuário com ID: ${id_usuario}`);
        response = await axios.get<User>(`https://tribhus.shop:5000/users/${id_usuario}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
      } else if (id_banda) {
        console.log(`Buscando dados da banda com ID: ${id_banda}`);
        response = await axios.get<Band>(`https://tribhus.shop:5000/bands/banda/${id_banda}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
      } else {
        console.log("Nem id_usuario nem id_banda estão disponíveis");
        return;
      }

      if (response && response.data) {
       setProfileData(response.data);
      } else {
        console.log("Nenhum dado recebido do backend");
      }
    } catch (error) {
      console.error('Erro ao buscar dados do perfil:', error);
      if (axios.isAxiosError(error)) {
        console.error('Detalhes do erro:', error.response?.data);
      }
    }
  };

  const fetchPosts = async () => {
    if (!token) {
      console.log("Token não disponível para buscar posts");
      return;
    }

    try {
      setLoading(true);
      const response = await axios.get<Postagem[]>('https://tribhus.shop:5000/palco', {
        params: {
          id_banda,
          id_usuario,
        },
        headers: { Authorization: `Bearer ${token}` },
      });
      
      console.log('Dados de posts recebidos do backend:', response.data);
      setPosts(response.data);

      // Carregar comentários para cada post
      response.data.forEach(post => fetchCommentsForPost(post.id_postagem));
    } catch (error) {
      console.error('Erro ao buscar posts:', error);
    } finally {
      setLoading(false);
    }
  };

  const isBand = (profile: ProfileData | null): profile is Band => {
    return profile !== null && 'nome_banda' in profile;
  };
    
  const fetchCommentsForPost = async (postId: number) => {
    if (!token) {
      console.log('Nenhum token disponível - usuário provavelmente deslogado');
      return;
    }

    try {
      const response = await axios.get(`https://tribhus.shop:5000/palco/${postId}/comments`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setPostComments(prevState => ({
        ...prevState,
        [postId]: response.data
      }));
    } catch (error) {
      console.error('Error fetching comments for post:', postId, error);
    }
  };

  const isOwner = (item: Postagem | Comment): boolean => {
    if (authState.id_usuario && item.id_usuario) {
      return authState.id_usuario === item.id_usuario;
    }
    if (authState.id_banda && item.id_banda) {
      return authState.id_banda === item.id_banda;
    }
    return false;
  };

  const handleDeleteClick = (postId: number, commentId?: number) => {
    console.log(`Iniciando exclusão - PostID: ${postId}, CommentID: ${commentId}`);
    setSelectedPostId(postId);
    setSelectedCommentId(commentId || null);
    setDeleteModalVisible(true);
  };

  const handleDeleteComplete = () => {
    console.log("Exclusão completada, recarregando dados");
    setDeleteModalVisible(false);
    fetchPosts(); // Recarregar os posts após a exclusão
  };

  const navigateToProfile = (slug: string, id_usuario?: number, id_banda?: number) => {
    slugDispatch({ type: 'SET_SLUG_PERFIL', slugPerfil: slug });
    
    if (id_usuario) {
      navigate('/TelaUsuarioPerfil');
    } else if (id_banda) {
      navigate('/TelaBandaPerfil');
    }
  };

  const handlePostClick = (postId: number) => {
    // Pause all videos
    Object.values(playerRefs.current).forEach(player => player.pause());
    setSelectedPostId(postId);
    setModalOpen(true);
  };

  const handleOpenCreatePost = () => {
    setIsCreatePostOpen(true);
  };

 
  const carregarBandasEmDestaque = useCallback(async () => {
    try {
      const response = await axios.get<{ bandas: Band[] }>(
        'https://tribhus.shop:5000/bands/all-bands?page=1&pageSize=5',
        {
          headers: {
            'Authorization': `Bearer ${authState.token}`
          }
        }
      );
      setBandasEmDestaque(response.data.bandas);
    } catch (error) {
      console.error("Erro ao carregar bandas em destaque: ", error);
    }
  }, [authState.token]);

  useEffect(() => {
    if (authState.token) {
      carregarBandasEmDestaque();
    }
  }, [authState.token, carregarBandasEmDestaque]);

  const handleBandaClick = useCallback((slug: string) => {
    slugDispatch({ type: 'SET_SLUG_PERFIL', slugPerfil: slug });
    navigate('/TelaBandaPerfil');
}, [slugDispatch, navigate]);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
    const year = date.getFullYear();
  
    return `${day}/${month}/${year}`;
  };

  return (
    <>
      <StyleSheet /> {/* Adiciona o StyleSheet para aplicar os estilos responsivos */}
      <div style={styles.container}>
        {!token && <p>Aguardando autenticação...</p>}
        {token && (
          <>
            <div style={styles.mainContent}>
              {/* Coluna esquerda com a logo, ícone de perfil e menu */}
              <div style={styles.leftColumn}>
                <img src={require('../assets/logotipo.png')} alt="Logotipo" style={styles.logo} />
                <div
                  style={{
                    ...styles.profileIcon,
                    backgroundImage: `url(${isBand(profileData) ? profileData?.url_icone_banda : profileData?.url_icone_usuario})`,
                  }}
                />
                <p style={styles.profileName}>
                  {isBand(profileData)
                    ? profileData?.nome_banda
                    : `${profileData?.nome ?? ''} ${profileData?.sobrenome ?? ''}`}
                </p>
                <div style={styles.menuContainer}>
                  <TabBar currentScreen="Palco" currentSlug={profileData?.slug} />
                </div>
                {currentTrack && (
                  <div style={styles.miniPlayerContainer}>
                    <MiniPlayer />
                  </div>
                )}
              </div>
  
              {/* Coluna do Feed */}
              <div style={styles.feedColumn}>
                <div style={styles.createPostButtonContainer}>
                  <button onClick={handleOpenCreatePost} style={styles.createPostButton}>
                    <img src={AddPost} alt="Criar Nova Postagem" style={styles.createPostIcon} />
                  </button>
                </div>
  
                {loading ? (
                  <div>Carregando...</div>
                ) : (
                  posts.map((post) => {
                    const videoId = post.video_url ? extractYouTubeVideoId(post.video_url) : null;
                    return (
                      <React.Fragment key={post.id_postagem}>
                        <div
                          key={post.id_postagem}
                          style={styles.postContainer}
                          onClick={() => handlePostClick(post.id_postagem)}
                        >
                          <div style={styles.postHeader}>
                            <div
                              style={styles.authorInfo}
                              onClick={() => navigateToProfile(post.slug, post.id_usuario, post.id_banda)}
                            >
                              <img
                                src={
                                  post.url_capa &&
                                  post.url_capa.trim() !== "https://sate.live:9000/tribhusmidias/"
                                    ? post.url_capa
                                    : noImage
                                }
                                alt="Autor da postagem"
                                style={{
                                  ...styles.authorIcon,
                                  backgroundColor: '#f0f0f0',
                                  height: '40px',
                                  width: '40px',
                                  borderRadius: '50%',
                                }}
                              />
                              <h4 style={styles.postTitle}>
                                {post.nome} {post.sobrenome}
                              </h4>
                            </div>
                            <div style={styles.postHeaderButtons} onClick={(e) => e.stopPropagation()}>
                              {isOwner(post) ? (
                                <MenuButton onClick={() => handleDeleteClick(post.id_postagem)} />
                              ) : (
                                <BotaoDenuncias
                                  itemId={post.id_postagem}
                                  tipoItem="postagem"
                                  onDenunciaEnviada={() =>
                                    console.log('Denúncia de postagem enviada com sucesso!')
                                  }
                                />
                              )}
                            </div>
                          </div>
                          {post.image_url && (
                            <img src={post.image_url} alt="Imagem da postagem" style={styles.postImage} />
                          )}
                          <p style={styles.postContent}>{post.conteudo}</p>
                          {videoId && (
  <div style={styles.videoContainer} onClick={() => handlePostClick(post.id_postagem)}>
    {post.video_url && post.video_url.includes("shorts/") ? (
      <iframe
        width="100%"
        height="315"
        src={`https://www.youtube.com/embed/${videoId}`}
        allowFullScreen
        style={{ ...styles.videoFrame, border: "none" }} // 🔹 Corrigido: border dentro do objeto style
      />
    ) : (
      <YouTubeVideo
        videoId={videoId}
        ref={(ref) => {
          if (ref) playerRefs.current[post.id_postagem] = ref;
        }}
      />
    )}
  </div>
)}
                          <p style={styles.postInfo}>{formatDate(post.data_hora)}</p>
                        </div>
  
                        {/* Container para ações do post */}
                        <div style={styles.postActionsContainer}>
                          <LikeButton
                            postId={post.id_postagem}
                            initialLikes={post.likes_count ?? 0}
                            user_or_banda_liked_id={post.user_or_banda_liked_id ?? null}
                            liked={Boolean(post.user_or_banda_liked_id)}
                            contentType="postagem"
                            slug={post.slug}
                          />
                          {post.comentarios_ativados && (
                            <img
                              src={commentIcon}
                              alt="Comentários"
                              style={styles.commentIcon}
                              onClick={() => handlePostClick(post.id_postagem)}
                            />
                          )}
                        </div>
  
                        {post.comentarios_ativados && (
                          <div style={styles.commentContainer}>
                            <p style={styles.postInfo}>Comentários:</p>
                            {postComments[post.id_postagem] &&
                              postComments[post.id_postagem].slice(0, 2).map((comment: Comment) => (
                                <div key={comment.id_comentario} style={styles.commentContainer}>
                                  <div style={styles.authorContainer}>
                                    {isOwner(comment) && (
                                      <MenuButton
                                        onClick={() =>
                                          handleDeleteClick(post.id_postagem, comment.id_comentario)
                                        }
                                      />
                                    )}
                                    <div
                                      style={styles.commentAuthorInfo}
                                      onClick={() =>
                                        navigateToProfile(
                                          comment.slug,
                                          comment.id_usuario,
                                          comment.id_banda
                                        )
                                      }
                                    >
                                      <img src={comment.url_perfil} alt="Perfil" style={styles.commentImage} />
                                      <p style={styles.commentAuthor}>
                                        {`${comment.nome} ${comment.sobrenome || ''}`}
                                      </p>
                                    </div>
                                  </div>
  
                                  <div style={styles.commentTextContainer}>
                                    <p style={styles.commentText}>{comment.texto}</p>
                                    {!isOwner(comment) && (
                                      <BotaoDenuncias
                                        itemId={comment.id_comentario}
                                        tipoItem="comentario"
                                        onDenunciaEnviada={() =>
                                          console.log('Denúncia de comentário enviada com sucesso!')
                                        }
                                      />
                                    )}
                                  </div>
                                  <p style={styles.commentDate}>{formatDate(comment.data_hora)}</p>
                                </div>
                              ))}
                            {postComments[post.id_postagem] &&
                              postComments[post.id_postagem].length > 2 && (
                                <button style={styles.moreCommentsButton}>
                                  <span style={styles.moreCommentsText}>mais comentários...</span>
                                </button>
                              )}
                          </div>
                        )}
                      </React.Fragment>
                    );
                  })
                )}
              </div>
  
              {/* Coluna direita com "Bandas em destaque" */}
            <div style={styles.rightColumn}>
              <h2 style={styles.rightColumnTitle}>Bandas em destaque</h2>
              {bandasEmDestaque.map((banda) => (
                <div
                  key={banda.id_banda}
                  style={styles.bandaCard}
                  onClick={() => banda.slug && handleBandaClick(banda.slug)}
                >
                  {banda.url_icone_banda ? (
                    <img
                      style={styles.bandaImage}
                      src={banda.url_icone_banda}
                      alt={banda.nome_banda || 'Banda'}
                    />
                  ) : (
                    <div style={styles.noImage}>
                      <span style={styles.noImageText}>Sem imagem</span>
                    </div>
                  )}
                  <span style={styles.bandaName}>{banda.nome_banda}</span>
                  <span style={styles.bandaGenero}>{banda.generos.slice(0, 2).join(', ')}</span>
                  <span style={styles.bandaLocation}>{banda.cidade} {banda.estado && `, ${banda.estado}`}</span>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
        <DeletePostComment
          isVisible={deleteModalVisible}
          onClose={() => setDeleteModalVisible(false)}
          postId={selectedPostId}
          commentId={selectedCommentId}
          onPostDeleted={handleDeleteComplete}
          onCommentDeleted={handleDeleteComplete}
        />
        {selectedPostId && (
          <Postagem
            isOpen={modalOpen}
            onClose={() => setModalOpen(false)}
            postId={selectedPostId}
          />
        )}
        <CreatePost isOpen={isCreatePostOpen} onClose={() => setIsCreatePostOpen(false)} />
      </div>
    </>
  );
};  

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    backgroundColor: '#151922',
    minHeight: '100vh',
    color: 'lightgray',
    padding: '20px',
    display: 'flex',
    justifyContent: 'center',
  },
  mainContent: {
    display: 'flex',
    padding: '0 20px',
    alignItems: 'flex-start',
    maxWidth: '1200px', // Limita a largura máxima do conteúdo principal
    width: '100%',
    gap: '20px', // Espaçamento entre as colunas padrão
  },
  leftColumn: {
    width: '250px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  feedColumn: {
    flex: 1,
    maxWidth: '700px', // Aumenta um pouco a largura máxima da coluna de feed
  },
  rightColumn: {
    width: '200px', // Diminui a largura da coluna "Bandas mais tocadas"
    padding: '20px',
    backgroundColor: '#111111',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: '30px',
  },
  rightColumnTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#FF6600',
    marginBottom: '40px',
  },
  bandaCard: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '40px',
    cursor: 'pointer',
  },
  bandaImage: {
    width: '140px', // Define um tamanho para a imagem da banda
    height: '140px',
    borderRadius: '10%',
    objectFit: 'cover',
    marginBottom: '10px',
  },
  bandaName: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#FFFFFF',
    textAlign: 'center',
    marginBottom: '5px',
  },
  bandaGenero: {
    fontSize: '12px',
    color: '#BBBBBB',
    textAlign: 'center',
  },
  bandaLocation: {
    fontSize: '12px',
    color: '#999999',
    textAlign: 'center',
  },
  noImage: {
    width: '140px',
    height: '140px',
    borderRadius: '10%',
    backgroundColor: '#333',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '10px',
  },
  noImageText: {
    fontSize: '10px',
    color: '#FFFFFF',
    textAlign: 'center',
  },
  createPostButton: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    zIndex: 1000,
  },
  createPostIcon: {
    width: '40px',
    height: '40px',
  },
  logo: {
    width: '140px',
    height: '220px',
    objectFit: 'contain',
  },
  profileIcon: {
    width: '160px',
    height: '160px',
    borderRadius: '50%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    border: '4px solid #151922',
    boxShadow: '0 2px 4px rgba(255,255,255,0.2)',
    marginBottom: '10px',
  },
  profileName: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: 'lightgray',
    marginBottom: '60px',
  },
  menuContainer: {
    width: '100%',
  },
  postContainer: {
    width: '100%',
    maxWidth: '700px',
    marginBottom: '20px',
    padding: '15px',
    borderRadius: '8px',
    backgroundColor: '#111111',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start', // Alinha o conteúdo do post à esquerda
  },
  authorIcon: {
    width: '20px', 
    height: '20px',
    borderRadius: '50%',
    marginRight: '10px',
    objectFit: 'cover',
  },
  postContent: {
    fontSize: '16px',
    marginBottom: '10px',
    textAlign: 'left', // Alinha o conteúdo do post à esquerda
    width: '100%',
  },
  postTitle: {
    fontSize: '18px',
    marginBottom: '10px',
    textAlign: 'left', // Alinha o título à esquerda
  },
  postInfo: {
    fontSize: '14px',
    color: '#aaa',
    marginBottom: '5px',
    textAlign: 'left', // Alinha a data à esquerda
  },
  commentContainer: {
    marginTop: 10,
    padding: 10,
    backgroundColor: 'transparent',
    borderRadius: 5,
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
    textAlign: 'left',
  },
  postHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between', // Garante que o nome e o botão fiquem em lados opostos
    marginBottom: '10px',
    width: '100%',
  },
authorInfo: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    flex: 1, // Ocupará o espaço disponível, permitindo que o botão fique no final
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
postHeaderButtons: {
    marginLeft: '10px', // Distância do botão em relação ao nome
    flexShrink: 0, // Impede que o botão de denúncia seja redimensionado
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  commentAuthorInfo: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  commentTextContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '5px',
  },
  commentText: {
    color: 'lightgray',
    marginBottom: 0,
    marginRight: '10px',
    flex: 1,
  },
  postImage: {
    maxWidth: '100%',
    maxHeight: '300px',
    height: 'auto',
    width: 'auto',
    marginBottom: '10px',
    borderRadius: '4px',
    objectFit: 'contain',
  },
  postVideo: {
    maxWidth: '100%',
    marginBottom: '10px',
    borderRadius: '4px',
  },
  authorContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 5,
  },
  commentImage: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    marginRight: '10px',
    objectFit: 'cover',
  },
  commentAuthor: {
    fontWeight: 'bold',
    marginBottom: 5,
    color: 'lightgray',
  },
  commentDate: {
    fontSize: 12,
    color: '#aaa',
    marginBottom: 5,
  },
  moreCommentsButton: {
    background: 'none',
    border: 'none',
    color: 'lightgray',
    cursor: 'pointer',
  },
  moreCommentsText: {
    textDecoration: 'underline',
  },
  postActionsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  denunciaButton: {
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '1px',
    backgroundColor: 'transparent',
    border: 'none',
    color: '#ff6347',
  },
};

export default Palco;