/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RegisterContext } from '../context/RegisterContext';
import { useAuth } from '../context/AuthContext';
import backgroundImg from '../assets/fundologin.png';
import logoImg from '../assets/logotipo.png';
import DocumentModal from '../documents/DocumentModal';

type DocumentType = 'sobre' | 'termos-banda' | 'termos-usuario' | 'privacidade' | 'guia-ajuda';

const PrimeiraTela: React.FC = () => {
  const navigate = useNavigate();
  const { state: registerState, dispatch: registerDispatch } = useContext(RegisterContext);
  const [isLoading, setIsLoading] = useState(false);
  const { state, login } = useAuth();
  const [modalContent, setModalContent] = useState<DocumentType | null>(null);
  const [hoveredButton, setHoveredButton] = useState<string | null>(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Detecta dispositivos móveis com regex moderna
    const isMobileDevice = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
    setIsMobile(isMobileDevice);
  }, []);  

  useEffect(() => {
    if (state.token) {
      navigate('/Palco');
    }
  }, [state.token, navigate]);

  useEffect(() => {
    registerDispatch({ type: 'SET_FORM', field: 'email', value: '' });
    registerDispatch({ type: 'SET_FORM', field: 'senha', value: '' });
    registerDispatch({ type: 'SET_ERROR', error: '' });
  }, [registerDispatch]);

  const handleLogin = async () => {
    setIsLoading(true);
    try {
      const loginSuccess = await login(registerState.email, registerState.senha, navigate); // Agora sem erro
  
      if (loginSuccess) {
        console.log('Login bem-sucedido');
        navigate('/Palco');
      } else {
        console.log('Login falhou');
        registerDispatch({ type: 'SET_ERROR', error: 'Login falhou. Verifique suas credenciais.' });
      }
    } catch (error) {
      console.error('Erro durante o login:', error);
      registerDispatch({ type: 'SET_ERROR', error: 'Ocorreu um erro durante o login. Tente novamente.' });
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleSignUp = () => {
    navigate('/TerceiraTela');
  };

  const handleForgotPassword = () => {
    navigate('/PasswordResetRequestScreen');
  };

  const openModal = (content: DocumentType) => {
    setModalContent(content);
  };

  const closeModal = () => {
    setModalContent(null);
  };

  return (
    <div css={styles.pageContainer}>
      <div css={styles.imageContainer}>
        {/* Logo e Título */}
        <div css={styles.logoContainer}>
          <img src={logoImg} alt="Logo" css={styles.logo} />
          <div css={styles.title}>Tribhus: Onde fãs e bandas se encontram!</div>
          <div css={styles.subtitle}>
            Aqui você vai encontrar os principais lançamentos do rock nacional, underground e independente.
          </div>
        </div>
  
        {/* Conteúdo Condicional */}
        {isMobile ? (
          <div css={styles.mobileMessageContainer}>
            <div css={styles.mobileMessage}>
              Para acessar a Tribhus no celular, instale nosso aplicativo:
            </div>
            <div css={styles.downloadButtonsContainer}>
              <a
                href="https://play.google.com/store/apps/details?id=com.tribhus"
                target="_blank"
                rel="noopener noreferrer"
                css={{
                  ...styles.downloadButton,
                  ...(hoveredButton === 'playstore' ? styles.downloadButtonHover : {}),
                }}
                onMouseEnter={() => setHoveredButton('playstore')}
                onMouseLeave={() => setHoveredButton(null)}
              >
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
                  alt="Baixar na Play Store"
                  css={styles.downloadImage}
                />
              </a>
              <a
                href="https://apps.apple.com/us/app/tribhus/id6739949821"
                target="_blank"
                rel="noopener noreferrer"
                css={{
                  ...styles.downloadButton,
                  ...(hoveredButton === 'appstore' ? styles.downloadButtonHover : {}),
                }}
                onMouseEnter={() => setHoveredButton('appstore')}
                onMouseLeave={() => setHoveredButton(null)}
              >
                <img
                  src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                  alt="Baixar na App Store"
                  css={styles.downloadImage}
                />
              </a>
            </div>
          </div>
        ) : (
          <div css={styles.formContainer}>
            <div css={styles.titleLogin}>Faça seu login ou cadastre-se</div>
            <input
              css={styles.input}
              onChange={(e) =>
                registerDispatch({ type: 'SET_FORM', field: 'email', value: e.target.value })
              }
              value={registerState.email}
              placeholder="Usuário"
            />
            <input
              css={styles.input}
              onChange={(e) =>
                registerDispatch({ type: 'SET_FORM', field: 'senha', value: e.target.value })
              }
              value={registerState.senha}
              placeholder="Senha"
              type="password"
            />
            <button css={styles.forgotPasswordButton} onClick={handleForgotPassword}>
              <span css={styles.forgotPasswordText}>Esqueceu a senha?</span>
            </button>
            <button css={styles.button} onClick={handleLogin} disabled={isLoading}>
              <span css={styles.buttonText}>{isLoading ? 'Carregando...' : 'LOGIN'}</span>
            </button>
            <button css={styles.signupButton} onClick={handleSignUp}>
              <span css={styles.signupText}>Não tem login? Faça seu cadastro!</span>
            </button>
            {registerState.error && <div css={styles.errorText}>{registerState.error}</div>}
  
            {/* Botões de download */}
            <div css={styles.downloadButtonsContainer}>
              <a
                href="https://play.google.com/store/apps/details?id=com.tribhus"
                target="_blank"
                rel="noopener noreferrer"
                css={{
                  ...styles.downloadButton,
                  ...(hoveredButton === 'playstore' ? styles.downloadButtonHover : {}),
                }}
                onMouseEnter={() => setHoveredButton('playstore')}
                onMouseLeave={() => setHoveredButton(null)}
              >
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
                  alt="Baixar na Play Store"
                  css={styles.downloadImage}
                />
              </a>
              <a
                href="https://apps.apple.com/us/app/tribhus/id6739949821"
                target="_blank"
                rel="noopener noreferrer"
                css={{
                  ...styles.downloadButton,
                  ...(hoveredButton === 'appstore' ? styles.downloadButtonHover : {}),
                }}
                onMouseEnter={() => setHoveredButton('appstore')}
                onMouseLeave={() => setHoveredButton(null)}
              >
                <img
                  src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                  alt="Baixar na App Store"
                  css={styles.downloadImage}
                />
              </a>
            </div>
  
            {/* Links de documentos */}
            <div css={styles.documentLinks}>
              <button css={styles.documentButton} onClick={() => openModal('sobre')}>
                Sobre
              </button>
              <button css={styles.documentButton} onClick={() => openModal('termos-banda')}>
                Termos de Uso da Banda
              </button>
              <button css={styles.documentButton} onClick={() => openModal('termos-usuario')}>
                Termos de Uso do Usuário
              </button>
              <button css={styles.documentButton} onClick={() => openModal('privacidade')}>
                Políticas de Privacidade
              </button>
              <button css={styles.documentButton} onClick={() => openModal('guia-ajuda')}>
                Guia de Ajuda
              </button>
            </div>
          </div>
        )}
  
        {/* Modal */}
        {modalContent && <DocumentModal content={modalContent} onClose={closeModal} />}
      </div>
  
      {/* Rodapé */}
      <div css={styles.footerContainer}>
        <div css={styles.footerText}>
          TRIBHUS DISTRIBUICAO DE CONTEUDO DIGITAL LTDA - CNPJ: 36.380.391/0001-64
        </div>
        <div css={styles.footerText}>
          Telefone: 19 9 9950-9709 | Email: admin@tribhus.com.br
        </div>
      </div>
    </div>
  );  
};  

const styles = {
  pageContainer: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    padding: '0 10px',
    backgroundColor: '#151922',
    backgroundImage: `url(${backgroundImg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }),
  imageContainer: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '5%',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      padding: '2rem',
    },
  }),
  logoContainer: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '1rem',
  }),
  logo: css({
    width: '300px',
    height: 'auto',
    '@media (max-width: 768px)': {
      width: '150px',
    },
  }),
  title: css({
    fontSize: '4rem',  // Dobro do tamanho original
    color: '#FF6600',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '0.5rem',
    '@media (max-width: 768px)': {
      fontSize: '1.5rem',  // Mantém o tamanho móvel
    },
  }),
  subtitle: css({
    fontSize: '1rem',
    color: '#fff',
    textAlign: 'center',
    marginBottom: '1.5rem',
  }),
  formContainer: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '400px',
    width: '100%',
    padding: '2rem',
    backgroundColor: '#1c1f27',
    borderRadius: '10px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
    '@media (max-width: 768px)': {
      padding: '1rem',
    },
  }),
  titleLogin: css({
    fontSize: '1.5rem',
    color: '#fff',
    textAlign: 'center',
    marginBottom: '2rem',
  }),
  input: css({
    width: '100%',
    height: '40px',
    borderColor: '#914100',
    borderWidth: '1px',
    borderRadius: '5px',
    marginBottom: '1rem',
    padding: '0.5rem',
    fontSize: '1rem',
    color: '#454545',
    '@media (max-width: 768px)': {
      fontSize: '0.9rem',
    },
  }),
  button: css({
    width: '100%',
    backgroundColor: '#914100',
    color: '#fff1e5',
    padding: '0.8rem',
    borderRadius: '5px',
    textAlign: 'center',
    marginBottom: '1rem',
    cursor: 'pointer',
    border: 'none',
    outline: 'none',
    fontSize: '1rem',
    '@media (max-width: 768px)': {
      fontSize: '0.9rem',
    },
  }),
  buttonText: css({
    color: 'inherit',
    fontSize: '1.2rem',
  }),
  signupButton: css({
    background: 'none',
    border: 'none',
    color: '#fff',
    cursor: 'pointer',
    padding: '10px',
    textAlign: 'center',
    marginBottom: '60px',
  }),
  signupText: css({
    fontSize: '1rem',
    color: '#FF6600',
    textDecoration: 'underline',
  }),
  errorText: css({
    color: 'red',
    marginBottom: '1rem',
    fontSize: '0.9rem',
  }),
  forgotPasswordButton: css({
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    padding: '5px',
    marginBottom: '10px',
    alignSelf: 'flex-end',
    textAlign: 'right',
  }),
  forgotPasswordText: css({
    color: '#FF6600',
    fontSize: '0.9rem',
    textDecoration: 'underline',
  }),
  downloadButtonsContainer: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',
    marginTop: '1.5rem',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      gap: '1rem',
    },
  }),
  downloadButton: css({
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    borderRadius: '8px',
    transition: 'transform 0.2s',
  }),
  downloadButtonHover: css({
    transform: 'scale(1.1)', // Efeito de destaque ao passar o mouse
  }),
  downloadImage: css({
    width: '150px',
    height: 'auto',
  }),
  documentLinks: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '1rem',
  }),
  documentButton: css({
    background: 'none',
    border: 'none',
    color: '#FF6600',
    cursor: 'pointer',
    padding: '0.5rem',
    margin: '0.5rem 0',
    fontSize: '0.9rem',
    textDecoration: 'underline',
  }),
  footerContainer: css({
    marginTop: '2rem',
    textAlign: 'center',
    color: '#bbb',
    fontSize: '0.8rem',
  }),
  footerText: css({
    color: '#bbb',
    fontSize: '0.8rem',
    marginBottom: '5px',
  }),
  mobileMessageContainer: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2rem',
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#1c1f27',
    borderRadius: '10px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
  }),
  mobileMessage: css({
    fontSize: '1.2rem',
    marginBottom: '1.5rem',
    color: '#FF6600',
  }),
};

export default PrimeiraTela;
